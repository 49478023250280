document.addEventListener("DOMContentLoaded", app);

function app() {
  // headerHeight();
  allSliders();
}

// function headerHeight() {
//   var header = document.querySelector('.header');
//   var headerHeight = header.offsetHeight;
//   var headerInsideOut = document.querySelector('.header_inside_out');
//   headerInsideOut.style.height = headerHeight + 'px';
// }

function allSliders() {

  // 
  const sliderElement = document.querySelector('.presents_swiper');
  if (sliderElement) {
    const swiper = new Swiper('.presents_swiper', {
        loop: true,
        slidesPerView: 1,
      
        pagination: {
          el: '.presents_pagination',
          clickable: true,
        },
        autoplay: {
            delay: 3000,
        },
      
        navigation: {
          nextEl: '.presents_btn_next',
          prevEl: '.presents_btn_prev',
        },
    });
  }
  // 
  // const sliderElement2 = document.querySelector('.first_info_item_img');
  // if (sliderElement2) {
  //   const swiper2 = new Swiper('.first_info_item_img', {
  //       loop: true,
  //       slidesPerView: 1,
      
  //       autoplay: {
  //           delay: 3000,
  //       },
  //   });
  // }

}